import React from 'react';
import './Menu.scss';
import closeIcon from '../icons/close.svg';

const Menu = React.forwardRef(({ isOpen, onClose }, ref) => {
  const handleCloseKeyDown = (event) => {
    // Handle the Tab key press, and NOT when shift is also held, if users goes backwards through DOM
    if (event.key === 'Tab' && !event.shiftKey) {
      event.preventDefault(); // prevent default tab behavior

      // Get the menu header element
      const menuHeader = document.querySelector('.menu-header').querySelector('h2');

      // Move focus to menu header element
      menuHeader.focus();
    }
  };
  const handleMenuHeaderKeyDown = (event) => {
    // Handle when Shift + Tab keys are pressed
    if (event.key === 'Tab' && event.shiftKey) {
      event.preventDefault(); // prevent default tab behavior

      // Get the menu close button element
      const menuClose = document.querySelector('.close-icon');

      // Move focus to menu close button element
      menuClose.focus();
    }
  };

  return (
    <div className={`menu ${isOpen ? 'open' : ''}`}>
      <div className="menu-header">
        <h2 tabIndex={-1} onKeyDown={handleMenuHeaderKeyDown}>Menu</h2>
      </div>
      <nav className="primary-links">
        <ul>
          <li><a href="#Home" tabIndex={-1}>Home</a></li>
          <li><a href="#Shop" tabIndex={-1}>Shop</a></li>
          <li><a href="#About" tabIndex={-1}>About Us</a></li>
        </ul>
      </nav>
      <nav className="secondary-links">
        <ul>
          <li><a href="#Contact" tabIndex={-1}>Contact</a></li>
          <li><a href="#Track" tabIndex={-1}>Track Your Order</a></li>
          <li><a href="#Return" tabIndex={-1}>Return Policy</a></li>
          <li><a href="#Shipping" tabIndex={-1}>Shipping Policy</a></li>
        </ul>
      </nav>
      <button className="close-icon" tabIndex={-1} onClick={onClose} onKeyDown={handleCloseKeyDown}>
        <img src={closeIcon} alt="Close" />
      </button>
    </div>
  );
});

export default Menu;
