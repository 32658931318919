import React, { useState, useRef, useEffect } from 'react';
import './App.scss';
import Header from './components/Header';
import Menu from './components/Menu';
import Home from './components/Home';

function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuAnimationDuration = parseInt(getComputedStyle(document.documentElement).getPropertyValue('--menu-animation-duration'));

  useEffect(() => {
    document.querySelectorAll('.menu [tabIndex]').forEach((el) => {
      el.tabIndex = isMenuOpen ? '0' : '-1';
    });
  }, [isMenuOpen]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);

    // Get the menu header element
    const menuHeader = document.querySelector('.menu-header').querySelector('h2');

    setTimeout(() => { // To account for how long it takes the menu to open
      menuHeader.focus();
    }, menuAnimationDuration);
  };

  const handleMenuClose = () => {
    setIsMenuOpen(false);

    // Get the menu icon button
    const menuIcon = document.querySelector('.menu-icon');

    setTimeout(() => { // To account for how long it takes the menu to open
      menuIcon.focus();
    }, menuAnimationDuration);
  };

  const handleEscape = (event) => {
    if (event.key === 'Escape') {
      setIsMenuOpen(false);

      // Get the menu icon button
      const menuIcon = document.querySelector('.menu-icon');
  
      setTimeout(() => { // To account for how long it takes the menu to open
        menuIcon.focus();
      }, menuAnimationDuration);
    }
  };

  return (
    <div className="App" onKeyDown={handleEscape}>
      <Header onMenuToggle={handleMenuToggle} />
      <Menu isOpen={isMenuOpen} onClose={handleMenuClose} />
      <Home />
    </div>
  );
}

export default App;
