import React from 'react';
import './Home.scss';

function Home() {
  return (
    <div className="home">
      <h2>The Watershed difference</h2>
      <div className="columns">
        <div className="column">
          <span>01</span>
          <h3>Best-in-class carbon accounting.</h3>
          <p>We employ a team of climate experts, data scientists, and carbon analysts to develop and vet cutting-edge data modelsand carbon accounting methologies. If you're looking for carbon data that meets the highest bar for regulatory scrutiny, go with Watershed.</p>
        </div>
        <div className="column">
          <span>02</span>
          <h3>Faster, easier reporting.</h3>
          <p>Watershed was built to help companies get to real carbon reduction, fast. We make it easy to ingest, clean, structure, and disclose your carbon data - so you can spend less time managing data, and more time on what matters.</p>
        </div>
        <div className="column">
          <span>03</span>
          <h3>Custom solutions.</h3>
          <p>Every standard is different, and every company has different needs. With our flexible software platform, you can build data categories, methologies, and reports that adapt as your needs change.</p>
        </div>
      </div>
    </div>
  );
}

export default Home;
